<script>
// import FullCalendar from "@fullcalendar/vue";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import bootstrapPlugin from "@fullcalendar/bootstrap";
// import listPlugin from "@fullcalendar/list";

// import { DatePicker } from "v-calendar";

import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

// import { calendarEvents, categories } from "./data-calendar";
import useNetw from "@useNetw";
import ModalServiceComponent from "@/components/modalServiceComponent.vue";

/**
 * Calendar component
 */
export default {
  page: {
    title: "ข้อมูลสินค้า",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, ModalServiceComponent }, // DatePicker
  data() {
    return {
      title: "ข้อมูลสินค้า",
      items: [
        {
          text: "ฝ่ายขาย",
          active: true,
        },
        {
          text: "สินค้า",
          active: false,
          href: "/product-sales",
        },
        {
          text: "ข้อมูลสินค้า",
          active: true,
        },
      ],
      submitted: false,
      submit: false,
      userBranch: "",
      rows: [],
      visible: false,
      index: 0,
      allImg: "",
      fullImg: "",
      currentPage: 1,
      coverImage: "",
      perPage: 10,
      overlayFlag: false,
    };
  },
  validations: {
    event: {
      title: { required },
      category: { required },
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    if (this.$route.params.prodIdBase64 != null) {
      this.getData();
    }
  },
  created() {
    // this.getData();
  },
  methods: {
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/d-product/show", {
          params: {
            prodId: atob(this.$route.params.prodIdBase64),
          },
        })
        .then((response) => {
          this.rows = response.data;
          this.allImg = response.data.data.coverImageThumbnail;
          this.coverImage = response.data.data.coverImage;
          // this.prodCode = response.data.data.prodCode;
          // this.brandId = {brandId: response.data.data.brandId, nameEn: response.data.data.vehicleBrandNameEn};
          // this.modelId = {modelId: response.data.data.modelId, nameEn: response.data.data.vehicleModelNameEn};
          // this.cc = {cc: response.data.data.cc};
          // this.editorData = response.data.data.description;
          // this.modelYear = {year: response.data.data.modelYear};
          // this.nameThProduct = response.data.data.nameTh;
          // this.nameEnProduct = response.data.data.nameEn;
          // this.shortNameTh = response.data.data.nameTh;
          // this.price = response.data.data.price;
          // this.prodTypeId = {prodTypeId: response.data.data.prodTypeId, nameTh: response.data.data.productTypeNameTh};
          // this.shortNameTh = response.data.data.shortNameTh;
          // this.shortNameEn = response.data.data.shortNameEn;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },

    confirm() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "ลบ",
      }).then((result) => {
        if (result.value) {
          this.deleteEvent();
          Swal.fire("Deleted!", "Event has been deleted.", "success");
        }
      });
    },
    showImg() {
      this.index = 0;
      this.visible = true;
      this.fullImage();
    },
    handleHide() {
      this.visible = false;
    },
    fullImage: function() {
      // this.overlayFlag = true;
      useNetw
        .get("api/d-product/image/full-size", {
          params: {
            fileId: this.coverImage,
          },
        })
        .then((response) => {
          this.fullImg = response.data.data.filePath;
          // this.allImg = response.data.data.coverImageThumbnail;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          // this.isHidden = true;
        });
    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

    /**
     * Show successfull Save Dialog
     */
    successmsg() {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Event has been saved",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    numberWithCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <!-- <span class="font-size-16">รายละเอียดสินค้า</span> -->
            <!-- <hr /> -->
            <b-overlay
              :show="overlayFlag"
              :spinner-variant="this.$overlayVariant"
              :spinner-type="this.$overlayType"
              :rounded="this.$overlayRounded"
            >
              <div class="row mt-4">
                <span
                  v-if="this.rows.total === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <!-- <div
                  class="col-6 col-sm-6 col-md-4"
                  v-for="(data, index) in rows"
                  :key="index"
                > -->
                <div class="col-6 col-sm-6 col-md-6">
                  <div class="product-detail">
                    <b-tabs pills vertical nav-wrapper-class="col-3">
                      <b-tab v-for="(data, index) in rows" :key="index">
                        <template v-slot:title>
                          <img
                            :src="data.coverImageThumbnail"
                            alt
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </template>
                        <div
                          class="float-start text-center m-2"
                          @click="() => showImg()"
                        >
                          <div class="product-img">
                            <img
                              :src="data.coverImageThumbnail"
                              alt
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                          <div class="col-12 text-center mt-2">
                            <button class="btn btn-primary text-center">
                              <i class="uil uil-shopping-basket me-2"></i>
                              ซื้อสินค้า
                            </button>
                          </div>
                        </div>
                        <vue-easy-lightbox
                          :visible="visible"
                          :imgs="fullImg"
                          @hide="handleHide"
                        ></vue-easy-lightbox>
                      </b-tab>
                      <!-- <b-tab>
                        <template v-slot:title>
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/zxcssad.png"
                            alt
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </template>
                        <div class="product-img">
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/zxcssad.png"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                        </div>
                      </b-tab>
                      <b-tab>
                        <template v-slot:title>
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/asdwwasd.png"
                            alt
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </template>
                        <div class="product-img">
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/asdwwasd.png"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                        </div>
                      </b-tab>
                      <b-tab>
                        <template v-slot:title>
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/asdxx.png"
                            alt
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </template>
                        <div class="product-img">
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/asdxx.png"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                        </div>
                      </b-tab>
                      <b-tab>
                        <template v-slot:title>
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/cb63-1.jpg"
                            alt
                            class="img-fluid mx-auto d-block tab-img rounded"
                          />
                        </template>
                        <div class="product-img">
                          <img
                            src="https://www.bigbikeinfo.com/wp-content/uploads/2017/01/cb63-1.jpg"
                            alt
                            class="img-fluid mx-auto d-block"
                          />
                        </div>
                      </b-tab> -->
                    </b-tabs>
                  </div>
                </div>
                <!-- <div class="col-6 col-sm-6 col-md-6">
                  <div class="product-box">
                    <div class="product-img pt-1 px-1">
                      <div class="product-ribbon badge badge-danger"></div>
                      <img
                        src="https://www.happeningandfriends.com/uploads/happening/products/46/004554/thumbnail/mock_ST_newSadCat.jpg"
                        alt
                        height="600"
                        class="img-fluid mx-auto d-block"
                        style="height: 10rem"
                      />
                    </div>
                  </div>
                </div> -->
                <div class="col-6 col-sm-6 col-md-6">
                  <div v-for="(data, index) in rows" :key="index">
                    <div>
                      <h4 class="font-size-22 mt-3">
                        {{ data.vehicleBrandNameEn }} {{ data.nameEn }}
                      </h4>
                    </div>
                    <div>
                      <!-- <del class="text-muted me-2">288,000</del> -->
                      <span class="font-size-16 text-danger">
                        {{ numberWithCommas(data.price) }} </span
                      >บาท
                      <!-- <span class="text-danger font-size-14 ms-2"
                        >ลด - 20 %</span
                      > -->
                    </div>
                    <div class="mt-3">
                      <label>รายละเอียด</label>
                      <p v-html="data.description" class="text-muted"></p>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
              <div class="mt-4">
                <!-- <h5 class="font-size-14 mb-3">Product description:</h5> -->
                <div class="product-desc">
                  <b-tabs
                    class="nav-tabs-custom"
                    content-class="border border-top-0 p-4"
                  >
                    <b-tab title="รายละเอียด" active>
                      <div
                        class="row"
                        v-for="(data, index) in rows"
                        :key="index"
                      >
                        <div class="col-sm-3 col-md-2">
                          <div>
                            <img
                              :src="data.coverImageThumbnail"
                              alt=""
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                        <div class="col-sm-9 col-md-10">
                          <div class="text-muted p-2">
                            <div v-html="data.description"></div>
                            <!-- <p>
                              Everyone realizes why a new common language would
                              be desirable, one could refuse to pay expensive
                              translators.
                            </p>
                            <p>It will be as simple as occidental in fact.</p>

                            <div>
                              <ul
                                class="list-unstyled product-desc-list text-muted"
                              >
                                <li>
                                  <i
                                    class="mdi mdi-circle-medium me-1 align-middle"
                                  ></i>
                                  Sed ut perspiciatis omnis iste
                                </li>
                                <li>
                                  <i
                                    class="mdi mdi-circle-medium me-1 align-middle"
                                  ></i>
                                  Neque porro quisquam est
                                </li>
                                <li>
                                  <i
                                    class="mdi mdi-circle-medium me-1 align-middle"
                                  ></i>
                                  Quis autem vel eum iure
                                </li>
                              </ul>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="ข้อมูลเพิ่มเติม">
                      <div class="table-responsive">
                        <table class="table table-nowrap mb-0">
                          <tbody v-for="(data, index) in rows" :key="index">
                            <tr>
                              <th scope="row" style="width: 20%">รหัสรุ่น</th>
                              <td>
                                {{
                                  data.model.modelCode != null
                                    ? data.model.modelCode
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style="width: 20%">ชื่อสินค้า</th>
                              <td>
                                {{
                                  data.model.nameTh != null
                                    ? data.model.nameTh
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style="width: 20%">กลุ่มรุ่น</th>
                              <td>
                                {{
                                  data.model.modelGroup != null
                                    ? data.model.modelGroup
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style="width: 20%">ปีรุ่น</th>
                              <td>
                                {{
                                  data.model.modelYear != null
                                    ? data.model.modelYear
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style="width: 20%">
                                เครื่องยนต์
                              </th>
                              <td>
                                {{
                                  data.model.engine != null
                                    ? data.model.engine
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style="width: 20%">
                                ปริมาตรกระบอกสูบ (ซีซี)
                              </th>
                              <td>
                                {{
                                  data.model.cylinderVolumePump != null
                                    ? data.model.cylinderVolumePump
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" style="width: 20%">
                                ความกว้างกระบอกสูบ x ช่วงชัก (มม.)
                              </th>
                              <td>
                                {{
                                  data.model.cylinderWidthSeizure != null
                                    ? data.model.cylinderWidthSeizure
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ล้อ</th>
                              <td>
                                {{
                                  data.model.wheel != null
                                    ? data.model.wheel
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระยะห่างล้อ</th>
                              <td>
                                {{
                                  data.model.wheelSpacing != null
                                    ? data.model.wheelSpacing
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระบบเบรค (หน้า)</th>
                              <td>
                                {{
                                  data.model.frontBrakeSystem != null
                                    ? data.model.frontBrakeSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระบบเบรค (หลัง)</th>
                              <td>
                                {{
                                  data.model.backBrakeSystem != null
                                    ? data.model.backBrakeSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระบบกันสะเทือน (หน้า)</th>
                              <td>
                                {{
                                  data.model.frontPreventQuakeSystem != null
                                    ? data.model.frontPreventQuakeSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระบบกันสะเทือน (หลัง)</th>
                              <td>
                                {{
                                  data.model.backPreventQuakeSystem != null
                                    ? data.model.backPreventQuakeSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ขนาดยาง (หน้า)</th>
                              <td>
                                {{
                                  data.model.frontTireSize != null
                                    ? data.model.frontTireSize
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ขนาดยาง (หลัง)</th>
                              <td>
                                {{
                                  data.model.backTireSize != null
                                    ? data.model.backTireSize
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">มุมล้อ</th>
                              <td>
                                {{
                                  data.model.casterCorners != null
                                    ? data.model.casterCorners
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระบบคลัทช์</th>
                              <td>
                                {{
                                  data.model.clutchSystem != null
                                    ? data.model.clutchSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">อัตราส่วนกำลังอัด</th>
                              <td>
                                {{
                                  data.model.compressionRatio != null
                                    ? data.model.compressionRatio
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระยะห่างจากพื้น (มม.)</th>
                              <td>
                                {{
                                  data.model.distanceBetweenFloor != null
                                    ? data.model.distanceBetweenFloor
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระบบเครื่องยนต์</th>
                              <td>
                                {{
                                  data.model.engineSystem != null
                                    ? data.model.engineSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">เฟรม</th>
                              <td>
                                {{
                                  data.model.frame != null
                                    ? data.model.frame
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <!-- <tr>
                              <th scope="row">น้ำมันเชื้อเพลิง</th>
                              <td>
                                {{
                                  data.model.oilFuel != null
                                    ? data.model.oilFuel
                                    : "-"
                                }}
                              </td>
                            </tr> -->
                            <tr>
                              <th scope="row">เชื้อเพลิง</th>
                              <td>
                                {{
                                  data.model.fuel != null
                                    ? data.model.fuel
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ความจุน้ำมัน</th>
                              <td>
                                {{
                                  data.model.apacity != null
                                    ? data.model.apacity
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">อัตราทดเกียร์</th>
                              <td>
                                {{
                                  data.model.gearRatio != null
                                    ? data.model.gearRatio
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">อัตราทดเกียร์</th>
                              <td>
                                {{
                                  data.model.gearRatio != null
                                    ? data.model.gearRatio
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <!-- <tr>
                              <th scope="row">GSP</th>
                              <td>
                                {{
                                  data.model.gsp != null ? data.model.gsp : "-"
                                }}
                              </td>
                            </tr> -->
                            <tr>
                              <th scope="row">ขนาด กว้าง x ยาว x สูง (มม.)</th>
                              <td>
                                {{
                                  data.model.width != null
                                    ? data.model.width
                                    : "-"
                                }}
                                x
                                {{
                                  data.model.length != null
                                    ? data.model.length
                                    : "-"
                                }}
                                x
                                {{
                                  data.model.height != null
                                    ? data.model.height
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ระบบจุดระเบิด</th>
                              <td>
                                {{
                                  data.model.ignitionSystem != null
                                    ? data.model.ignitionSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">น้ำหนักสุทธิ</th>
                              <td>
                                {{
                                  data.model.netWeight != null
                                    ? data.model.netWeight
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <!-- <tr>
                              <th scope="row">การส่งผ่าน</th>
                              <td>
                                {{
                                  data.model.transmission != null
                                    ? data.model.transmission
                                    : "-"
                                }}
                              </td>
                            </tr> -->
                            <tr>
                              <th scope="row">ระบบส่งกำลัง</th>
                              <td>
                                {{
                                  data.model.transmissionSystem != null
                                    ? data.model.transmissionSystem
                                    : "-"
                                }}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">ความสูงของเบาะ</th>
                              <td>
                                {{
                                  data.model.upholsteryHeight != null
                                    ? data.model.upholsteryHeight
                                    : "-"
                                }}
                              </td>
                            </tr>

                            <tr>
                              <th scope="row"><img src="../../../assets/images/idling.png" alt=""></th>
                              <td>
                                <span class="badge bg-danger"
                                  >{{ data.model.idlingStop == 0 ? "❌" : "✔" }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><img src="../../../assets/images/combibrake.png" alt=""></th>
                              <td>
                                <span class="badge bg-danger">{{
                                  data.model.combiBrake == 0 ? "❌" : "✔"
                                }}</span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><img src="../../../assets/images/esp.png" alt=""></th>
                              <td>
                                <span class="badge bg-danger"
                                  >{{ data.model.gsp == 0 ? "❌" : "✔" }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><img src="../../../assets/images/pgm-fi.png" alt=""></th>
                              <td>
                                <span class="badge bg-danger"
                                  >{{ data.model.pgmfi == 0 ? "❌" : "✔" }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><img src="../../../assets/images/smarttechnology.png" alt=""></th>
                              <td>
                                <span class="badge bg-danger"
                                  >{{
                                    data.model.smartTechnology == 0 ? "❌" : "✔"
                                  }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">EM6</th>
                              <td>
                                <span class="badge bg-danger"
                                  >{{ data.model.em6 == 0 ? "❌" : "✔" }}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <th scope="row"><img src="../../../assets/images/em7.png" alt=""></th>
                              <td>
                                <span class="badge bg-danger"
                                  >{{ data.model.em7 == 0 ? "❌" : "✔" }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
    <modal-service-component></modal-service-component>
  </Layout>
</template>

<style>
.font-green {
  color: green;
}

.img-fluid-estimation {
  max-width: 100%;
  height: 10rem;
}
</style>
